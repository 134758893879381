<template>
  <div>
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <!-- User Info: Left col -->
      <b-col md="6" class="d-flex justify-content-between flex-column">
        <b-card class="h-100 cardH">
          <!-- User Avatar & Action Buttons -->
          <b-row>
            <b-col cols="12" class="mt-2">
              <div class="d-flex personal-info justify-content-start">
                <CoolLightBox
                  :items="profileArr"
                  :index="imgIndex"
                  @close="imgIndex = null"
                >
                </CoolLightBox>
                <b-avatar
                  @click="imgIndex = 0"
                  :src="userData.profile_picture"
                  :alt="userData.firstname"
                  size="100"
                />
                <div class="d-flex flex-column ml-1">
                  <div class="">
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.First_Name") }}:
                      </span>
                      {{ userData.firstname }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.Last_Name") }}:
                      </span>
                      {{ userData.lastname }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.Email") }}:
                      </span>
                      {{ userData.email }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.Status") }}:
                      </span>
                      <b-badge variant="warning"> {{ status }} </b-badge>
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >نوع المنتجات :
                      </span>
                      <b-badge
                        v-if="userData.is_sell_new"
                        variant="primary"
                        class="mx-1"
                      >
                        بيع جديد
                      </b-badge>
                      <b-badge
                        v-if="userData.is_sell_used"
                        variant="secondary"
                        class="mx-1"
                      >
                        بيع مستعمل
                      </b-badge>
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >التصنيف:
                      </span>
                      <b-badge
                        v-if="userData.portal"
                        variant="primary"
                        class="mx-1"
                      >
                        بيع منصة
                      </b-badge>
                      <b-badge
                        v-if="userData.in_store"
                        variant="secondary"
                        class="mx-1"
                      >
                        بيع مباشر
                      </b-badge>
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.Birthdate") }}:
                      </span>
                      {{ userData.birthdate }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.ID_Number") }}:
                      </span>
                      {{ userData.idnumber }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #046D6D"
                        >{{ $t("Show_Vendor.Code") }}:
                      </span>
                      {{ userData.code }}
                    </h5>
                    <hr />
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8F86F3"
                        >إسم المسؤل:
                      </span>
                      {{ userData.internal_manager_name }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8F86F3"
                        >رقم تليفون المسؤل:
                      </span>
                      {{ userData.internal_manager_phone }}
                    </h5>
                    <h5 class="mb-1">
                      <feather-icon
                        icon="UserIcon"
                        class="mr-75"
                        style="color:#046D6D; "
                      />{{ $t("Show_Vendor.ID") }} :

                        <span>
                        <img
                          v-b-modal.modal-id-pictures
                          :src="require('@/assets/images/icons/photo-up.svg')"
                        />
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      
      
      <!-- Madal For id-pictures User Action-->
      <b-modal
        id="modal-id-pictures"
        cancel-variant="outline-secondary"
        ok-title="Ok"
        cancel-title="Close"
        centered
        title="ID Pictures"
      >
        <b-row>
          <b-col cols="12" md="6" class="mt-2">
            <b-card title="ID fornt picture">
              <div class="d-flex justify-content-start">
                <CoolLightBox
                  :items="profileArr"
                  :index="img2Index"
                  @close="img2Index = null"
                >
                </CoolLightBox>
                <b-img
                  @click="img2Index = 0"
                  rounded
                  :src="userData.idfront_picture"
                  :alt="userData.firstname"
                  style="width: 100%; height: auto"
                />
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 h-100">
            <b-card title="ID back picture">
              <div class="d-flex justify-content-start">
                <CoolLightBox
                  :items="profileArr"
                  :index="img3Index"
                  @close="img3Index = null"
                >
                </CoolLightBox>
                <b-img
                  @click="img3Index = 0"
                  rounded
                  :src="userData.idback_picture"
                  :alt="userData.firstname"
                  style="width: 100%; height: auto"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
      <b-col md="6">
        <b-card class="h-100">
          <b-row>
            <b-col md="6">
              <div class="d-flex justify-content-center">
                <b-button
                  block
                  :to="{ path: '/vendor/update' }"
                  variant="primary mr-1 mt-1"
                >
                  {{ $t("Show_Vendor.Edit_Profile") }}
                </b-button>
              </div>
            </b-col>
            <b-col md="6">

                
              <div class="d-flex justify-content-center">
                <b-button
                  block
                  :to="{ path: '/vendor/password/change' ,
                      }"
                  variant="secondary mr-1 mt-1"
                >
                  {{ $t("Show_Vendor.Change_Password") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        
          <hr />
          <div class=" d-flex justify-content-around text-center">
            <div class="date-section ">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Show_Vendor.Created_at") }} :
              </h6>
              <p class="mb-0">
                {{ userData.created_at }}
              </p>
            </div>
            <div class="date-section">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Show_Vendor.Updated_at") }} :
              </h6>
              <p class="mb-0">
                {{ userData.updated_at }}
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Right Col: Table -->
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card :title="$t('Show_Vendor.Main_Data')">
          <b-row>
            <b-col md="8">
              <table style="width:100%">
                <tbody style="width:100%">
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.First_Name") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.firstname }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Last_Name") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.lastname }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Email") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.email }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Status") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">
                        <b-badge variant="primary"> {{ status }} </b-badge>
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Birthdate") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.birthdate }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.ID_Number") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.idnumber }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Store_Name") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.storename }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Type") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.type }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Address") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.address }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Order_Location") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.orderlocation }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Area") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ area }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Land_Phone") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.phone }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 class="mt-1">
                        <span style="font-weight: bold; color: #046D6D"
                          >{{ $t("Show_Vendor.Code") }}:</span
                        >
                      </h5>
                    </td>
                    <td>
                      <h5 class="mt-1">{{ userData.code }}</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12">
        <!-- <b-card :title="$t('Show_Vendor.Account_Manager')">
          <table id="table" v-if="userData.self_management == false">
            <tr>
              <th>{{ $t("Show_Vendor.Name") }}</th>
              <th>{{ $t("Show_Vendor.Email") }}</th>
            </tr>
            <tr>
              <td>
                {{
                  userData.account_manager.firstname +
                    " " +
                    userData.account_manager.lastname
                }}
              </td>
              <td>{{ userData.account_manager.email }}</td>
            </tr>
          </table>

          <b-badge variant="warning" v-else>
            {{ $t("Show_Vendor.No_Account_Manager") }}
          </b-badge>
        </b-card> -->


        <b-card :title="$t('Show_Vendor.available_sales_methods')">
                <table id="table" class="table  text-center"   >
                  <tbody>
                 
                    <tr>
                      <td>
                      </td>
                      <td>
                        <h5 >
                          منصة جديد
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          منصة مستعمل
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          مباشر جديد
                        </h5>
                      </td>
                      <td>
                        <h5 >
                          مباشر مستعمل
                        </h5>
                      </td>


                      <tr v-for="(category, index) in userData.categories" :key="index">
                      <td style="min-width: 140px;">
                        <h5 v-for="translation in  category.translation" :key="translation.id">
                          <span style="font-weight: bold; color: #0d6efd;"  v-if="translation.locale == 'ar'"
                            >{{translation.name }}:
                            </span
                          >
                        </h5>
                      </td>

                      <td>
                        <h5  v-if="category.pivot.is_portal_new">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>
                     
                      <td>
                        <h5  v-if="category.pivot.is_portal_used">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>


                      <td>
                        <h5 v-if="category.pivot.is_instore_new">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>

                      <td>
                        <h5 v-if="category.pivot.is_instore_used == true">
                          <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                        </h5>
                      </td>
                    </tr>
                  </tbody>

                </table>

              </b-card>



      </b-col>


      <b-col cols="12">
        <b-card :title="$t('Show_Vendor.Account_Manager')">
          <table id="table" v-if="userData.self_management == false">
            <tr>
              <th>{{ $t("Show_Vendor.Name") }}</th>
              <th>{{ $t("Show_Vendor.Email") }}</th>
            </tr>
            <tr>
              <td>
                {{
                  userData.account_manager.firstname +
                    " " +
                    userData.account_manager.lastname
                }}
              </td>
              <td>{{ userData.account_manager.email }}</td>
            </tr>
          </table>

          <b-badge variant="warning" v-else>
            {{ $t("Show_Vendor.No_Account_Manager") }}
          </b-badge>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card :title="$t('Show_Vendor.Mobiles_Number')">
          <!-- <h5 style="line-height: 2;"><span style="font-weight: bold; color: #046D6D">{{$t("Show_Vendor.Land_Phone")}}: </span> {{userData.phone}}</h5> -->
          <b-button
            class="mb-2 float-right"
            @click="mob = !mob"
            variant="success"
            >{{ $t("Show_Vendor.Add_New_Mobile_Number") }}</b-button
          >
          <table id="table">
            <tr>
              <th>{{ $t("Show_Vendor.ID") }}</th>
              <th>{{ $t("Show_Vendor.Mobiles_Number") }}</th>
              <th>{{ $t("Show_Vendor.Actions") }}</th>
            </tr>
            <tr v-for="(item, index) in userData.phoneNumber" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.mobile }}</td>
              <td>
                <b-button
                  v-if="index > 1"
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deleteMobile(item.id)"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width:18px; height:18px;"
                    class="align-middle"
                  />
                </b-button>
              </td>
            </tr>
          </table>

          <div v-show="mob" class="mt-5">
            <b-row>
              <!-- Mobile Number -->
              <b-col md="6">
                <b-form-group
                  :label="$t('Show_Vendor.Mobiles_Number')"
                  label-for="mobilenumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mobile Number"
                    rules="required|digits:11"
                  >
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="0115XXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-button
                  class="mt-2"
                  @click="saveMobileNumber()"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  {{ $t("Show_Vendor.Save") }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card :title="$t('Show_Vendor.Documents')">
          <table id="table">
            <tr>
              <th>{{ $t("Show_Vendor.ID") }}</th>
              <th>{{ $t("Show_Vendor.Name") }}</th>
              <th>{{ $t("Show_Vendor.File_Number") }}</th>
              <th>{{ $t("Show_Vendor.Files") }}</th>
            </tr>
            <tr v-for="item in userData.officcialDocuments" :key="item.id">
              <td>{{ item.id }}</td>
              <td style=" text-transform: capitalize">
                {{ item.name.replace("_", " ") }}
              </td>
              <td>{{ item.number }}</td>
              <td>
                <a :href="item.file">{{
                  $t("Show_Vendor.Press_Here_To_Open_File")
                }}</a>
              </td>
            </tr>
          </table>
        </b-card>
        <b-card :title="$t('Show_Vendor.Payouts_Accounts')">
          <table id="table">
            <tr>
              <th>{{ $t("Show_Vendor.ID") }}</th>
              <th>{{ $t("Show_Vendor.Name") }}</th>
              <th>{{ $t("Show_Vendor.File_Number") }}</th>
              <th>{{ $t("Show_Vendor.Files") }}</th>
            </tr>
            <tr v-for="item in userData.payouts_accounts" :key="item.id">
              <td>{{ item.id }}</td>
              <td style=" text-transform: capitalize">
                {{ item.name}}
              </td>
              <td>{{ item.number }}</td>
              <td>
                <a :href="item.file">{{
                  $t("Show_Vendor.Press_Here_To_Open_File")
                }}</a>
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col v-if="userData.commission" cols="12">
        <b-card>
          <card-statistics-group
            title="statistics"
            :statisticsItems="statistics"
          />
          <b-button
            block
            :to="{ path: '/vendor/commissions/edite/' + comm_id }"
            variant="warning"
          >
            <feather-icon icon="EditIcon" style="width:18px; height:18px;" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>


import { heightTransition } from "@core/mixins/ui/transition";
import CardStatisticsGroup from "./CardStatisticsGroup.vue";
import { required, email, integer } from "@validations";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  mixins: [heightTransition],
  components: {

    CoolLightBox,
    CardStatisticsGroup,

  },
  
  data() {
    return {
      officialUpdateShow: false,
      officialUpdateID: null,
      required,
      email,
      integer,
      mob: false,
      phone: null,
      mobileNumberData: {
        phoneNumbers: [
          {
            mobile: null,
          },
        ],
      },
      official: false,
      typeFileOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      addOfficial: {
        name: null,
        file: null,
        number: null,
      },
      updateOfficial: {
        file: null,
        number: null,
      },
      addPayout: {
        name: null,
        file: null,
        number: null,
      },
      typeFileSelect: null,
      payouts: false,
      productsClick: false,
      offersClick: false,
      payoutsClick: false,
      showID: false,
      per: null,
      userData: {},
      errors_back: [],
      showDismissibleAlert: false,
      id: 0,
      status: "",
      area: "",
      delUser: true,
      imgIndex: null,
      img2Index: null,
      img3Index: null,
      profileArr: [],
      frontArr: [],
      backArr: [],
      statistics: [],
      comm_id: "",
      deactive: "0",
      deletedVendor: "0",
      selectedTwo: {
        products: [],
      },
      selected: [],
      options: [],
      loadMore: true,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("profile")
        .then((result) => {
          this.userData = result.data.data;
          this.status = this.userData.status.name;
          this.area = this.userData.area.name;
          this.profileArr.push(this.userData.profile_picture);
          this.frontArr.push(this.userData.idfront_picture);
          this.backArr.push(this.userData.idback_picture);
          if (this.userData.type == "personal") {
            this.typeFileOptions = [
              {
                value: "select_value",
                text: "Select Value",
              },
              {
                value: "contract",
                text: "Contract",
              },
            ];
          } else {
            this.typeFileOptions = [
              {
                value: "select_value",
                text: "Select Value",
              },
              {
                value: "commercial_register ",
                text: "Commercial Register ",
              },
              {
                value: "tax_card ",
                text: "Tax Card ",
              },
              {
                value: "contract",
                text: "Contract",
              },
            ];
          }
          if (this.userData.deleted_at != null) {
            this.delUser = false;
          } else {
            this.delUser = true;
          }
          // this.comm_id = this.userData.commission.id
          // // set commision here
          // this.statistics.length = 5
          // this.statistics[0] = {
          //     icon: 'DollarSignIcon',
          //     color: 'light-primary',
          //     title: this.userData.commission.total_sale,
          //     subtitle: 'Total Sale',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[1] = {
          //     icon: 'PercentIcon',
          //     color: 'light-info',
          //     title: this.userData.commission.rate,
          //     subtitle: 'Rate',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[2] = {
          //     icon: 'BoxIcon',
          //     color: 'light-danger',
          //     title: this.userData.commission.commission_amount,
          //     subtitle: 'Commission Amount',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[3] = {
          //     icon: 'TrendingUpIcon',
          //     color: 'light-success',
          //     title: this.userData.commission.received_amount,
          //     subtitle: 'Received Amount',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[4] = {
          //     icon: 'TrendingDownIcon',
          //     color: 'light-info',
          //     title: this.userData.commission.remaining_amount,
          //     subtitle: 'Remaining Amount',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[5] = {
          //     icon: 'ArrowUpIcon',
          //     color: 'light-warning',
          //     title: this.userData.commission.last_paied,
          //     subtitle: 'Last Paied',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[6] = {
          //     icon: 'DollarSignIcon',
          //     color: 'light-primary',
          //     title: this.userData.commission.offer_amount,
          //     subtitle: 'Offer Amount',
          //     customClass: 'mb-2 mb-xl-0',
          // }
          // this.statistics[7] = {
          //     icon: 'BoxIcon',
          //     color: 'light-info',
          //     title: this.userData.commission.bundle_offer,
          //     subtitle: 'Bundle Offer',
          //     customClass: 'mb-2 mb-xl-0',
          // }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    fileUploadOfiicial(e) {
      this.addOfficial.file = e.target.files[0];
    },
    fileUploadPayout(e) {
      this.addPayout.file = e.target.files[0];
    },
    fileUpdateOfiicial(e) {
      this.updateOfficial.file = e.target.files[0];
    },
    officialUpdate(id, number) {
      this.officialUpdateShow = !this.officialUpdateShow;
      this.officialUpdateID = id;
      this.updateOfficial.number = number;
    },
    updateOfficalDocument() {
      let formData = new FormData();

      formData.append("file", this.updateOfficial.file);
      formData.append("number", this.updateOfficial.number);
      axios
        .put(
          "seller/vendors/official-docs/" + this.officialUpdateID + "/update",
          formData
        )
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    saveMobileNumber() {
      this.mobileNumberData.phoneNumbers[0].mobile = this.phone;
      axios
        .post("numbers/create", this.mobileNumberData)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    saveOfficalDocument() {
      let formData = new FormData();
      this.addOfficial.name = this.typeFileSelect.value;
      formData.append("name", this.addOfficial.name);
      formData.append("file", this.addOfficial.file);
      formData.append("number", this.addOfficial.number);
      axios
        .post("seller/vendors/" + this.id + "/official-docs/store", formData)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    savePayoutDocument() {
      let formData = new FormData();
      formData.append("name", this.addPayout.name);
      formData.append("file", this.addPayout.file);
      formData.append("number", this.addPayout.number);
      axios
        .post("seller/vendors/" + this.id + "/payouts-accounts/store", formData)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    deletePayoutDocument(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete file.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("seller/vendors/payouts-accounts/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    // delete Officail Document
    deleteOfficialDocument(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete file.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("seller/vendors/official-docs/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1;
      axios
        .get(
          "vendors/" +
            this.userData.id +
            "/products-pagi/50?page=" +
            this.currentPage
        )
        .then((result) => {
          const products = result.data.data.data;
          this.loadMore = false;
          if (products.length > 0) {
            for (let index in products) {
              this.selected.push({
                product_id: null,
                variants: [],
              });
              if (products[index].translation != null) {
                this.options.push({
                  text: products[index].translation.name,
                  value: products[index].id.toString(),
                  variants: [],
                });
              } else {
                this.options.push({
                  text: "undefinded",
                  value: products[index].id.toString(),
                  variants: [],
                });
              }
              for (let ind in products[index].variants) {
                this.selected[index].variants.push();
                if (products[index].variants[ind].translation != null) {
                  this.options[index].variants.push({
                    text: products[index].variants[ind].translation.name,
                    value: products[index].variants[ind].id.toString(),
                  });
                } else {
                  this.options[index].variants.push({
                    text: "undefinded",
                    value: products[index].variants[ind].id.toString(),
                  });
                }
              }
            }
            // $state.loaded();
          } else {
          }
          $state.complete();
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    resetData() {
      this.currentPage = 0;
      this.options.length = 0;
      this.selected.length = 0;
      this.selectedTwo.products = [];
    },
    activeVendor(id) {
      this.selectedTwo.products.length = 0;
      var contu = true;
      for (let index in this.selected) {
        if (
          this.selected[index].product_id != null &&
          !this.selected[index].variants.every((element) => element === null)
        ) {
          this.selectedTwo.products.push({
            product_id: this.selected[index].product_id,
            variants: [],
          });
          for (let cour in this.selected[index].variants) {
            if (this.selected[index].variants[cour] != null) {
              this.selectedTwo.products[
                this.selectedTwo.products.length - 1
              ].variants.push(this.selected[index].variants[cour]);
            }
          }
        } else if (
          (this.selected[index].product_id != null &&
            this.selected[index].variants.every(
              (element) => element === null
            )) ||
          (this.selected[index].product_id == null &&
            !this.selected[index].variants.every((element) => element === null))
        ) {
          this.$bvModal.msgBoxConfirm(
            " Wrong, Must be select an area and then select product's variants, please try again.",
            {
              title: "Are You Sure?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Ok",
              hideHeaderClose: false,
              centered: true,
            }
          );
          contu = false;
          break;
        } else {
        }
      }
      if (contu == true) {
        this.$bvModal
          .msgBoxConfirm("Please confirm that you want to active vendor.", {
            title: "Are You Sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .post("vendors/" + id + "/active-vendor", this.selectedTwo)
                .then((result) => {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.fetchData();
                })
                .catch((err) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Error!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.errors_back.length = 0;
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      });
                    } else {
                      this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                  } else {
                    this.errors_back = [];
                    this.errors_back.push({
                      error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                  }
                });
            }
          });
      }
    },
    deActiveVendor(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to deactive acount.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("actions/vendors/" + id + "/deactive/" + this.deactive)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteVendor(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to close acoount.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("actions/vendors/" + id + "/archive/" + this.deletedVendor)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteMobile(id) {
      this.$bvModal
        .msgBoxConfirm("يرجى تأكيد أنك تريد حذف الهاتف المحمول.", {
          title: "هل أنت متأكد؟",
          size: "sm",
          okVariant: "danger",
          okTitle: "نعم",
          cancelTitle: "لا",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("number/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore vendor.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("vendors/" + id + "/restore")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style scoped>
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}

@media only screen and (max-width: 425px) {
  .personal-info {
    justify-content: unset !important;
    flex-direction: column !important;
    align-items: center;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
